<template>
    <div :class="`sodoghe-style pa-2 ${direction == 'column' ? '' : 'row'}`">
        <div class="buonglai"></div>
        <div class="wheel-font-left"></div>
        <div class="wheel-font-right"></div>
        <div class="wheel-back-left"></div>
        <div class="wheel-back-right"></div>
        <div class="guong-trai"></div>
        <div class="guong-phai"></div>
        <div style="position: relative; z-index: 2">
            <div
                :class="`justify-space-around dauxe pb-3 ${
                    direction == 'column' ? 'row full-height' : 'column'
                }`"
            >
                <div
                    :class="`denxe ${direction == 'column' ? '' : 'rotate'}`"
                    style="width: 80px; height: 56px"
                ></div>
                <div
                    :class="`denxe ${direction == 'column' ? '' : 'rotate'}`"
                    style="width: 80px; height: 56px"
                ></div>
            </div>
        </div>

        <div
            :class="`justify-space-between ${
                direction == 'column' ? 'row dauxe' : 'column'
            }`"
            :style="
                direction == 'column' ? '' : 'flex-direction: column-reverse'
            "
            style="padding: 0px 8px"
        >
            <div
                style="
                    max-width: 88px;
                    margin: 0 24px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                "
                :style="`width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
            >
                <div class="donghotrai"></div>
                <div class="donghoto"></div>
                <div class="donghophai"></div>
                <div class="bangdientu"></div>

                <i
                    class="mdi mdi-steering"
                    style="position: relative; z-index: 2; font-size: 64px"
                    :style="`opacity: ${
                        getTangXeDaChon.tenTang == 'Tầng 1' ? 1 : 0
                    }`"
                ></i>
            </div>
            <div
                style="max-width: 88px; margin: 0 8px"
                :style="`width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 88px; margin: 0 8px"
                :style="`width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 88px; margin: 0 8px"
                :style="`width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 88px; margin: 0 8px"
                :style="`width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
            ></div>
            <!-- <div
                :style="direction == 'column' ? 'width:40%' : 'height:70%'"
            ></div>
            <i
                :class="`mdi mdi-exit-to-app pr-5 cuaravao ${
                    direction == 'column' ? '' : 'rotate'
                }`"
            ></i> -->
        </div>

        <div
            style="
                position: relative;
                z-index: 2;
                margin-top: -36px;
                height: calc(100% - 128px);
            "
        >
            <div
                class="xs12 scroll-sodoghe-style"
                style="overflow: auto; height: 100%; padding: 8px 24px"
                :style="
                    direction == 'column'
                        ? 'padding-botom:30px'
                        : 'padding-right:30px'
                "
            >
                <div
                    v-for="(dong, i) in getTangXeDaChon.soHang"
                    :key="i"
                    class="full-width hang-xe row justify-space-between"
                    :style="`height: calc(100%/${getTangXeDaChon.soHang} - 24px);`"
                    :class="getTangXeDaChon.soHang - 1 == i ? '' : 'mb-4'"
                >
                    <div
                        v-for="(cot, j) in getTangXeDaChon.soCot"
                        :key="j"
                        :style="`${item.style}; width: calc((100%/${getTangXeDaChon.soCot}) - 16px);`"
                        class="vitri"
                    >
                        <div
                            @click="chonCho(dong, cot)"
                            style="position: relative; z-index: 2"
                        >
                            <!-- @click="
                                                checkRole('NhanVienBanVeBenXe')
                                                    ? chonCho(dong, cot)
                                                    : null
                                            " -->
                            <div
                                v-html="hienThiCho(dong, cot)"
                                class="full-height"
                                :class="
                                    !isEmpty(dong, cot) ? 'isEmptySlot' : ''
                                "
                            ></div>
                            <DxCheckBox
                                v-if="usecheckbox && duocPhepChon(dong, cot)"
                                :value="checked(dong, cot)"
                                :read-only="true"
                                class="pa-1 checkbox-style"
                                style="position: absolute; top: 0; left: 18px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="thanhxe"
            :style="`${direction == 'column' ? 'top:52px' : 'left: 55px'}`"
        ></div>
    </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { ref, reactive } from "vue";
import { DxCheckBox } from "devextreme-vue";
// import { ChoNgoi } from "~/class/PhuongTien";
// import TaiKhoan from "~/class/TaiKhoan";
export default {
    components: {
        DxCheckBox,
    },
    props: {
        height: { type: String, default: "400px" },
        width: { type: String, default: "100%" },
        item: {
            type: Object,
            default: () => ({ height: "38px", width: "38px", style: "" }),
        },
        direction: { type: String, default: "column" },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        usecheckbox: { type: Boolean, default: true },
        useborder: { type: Boolean, default: false },
        usebackground: { type: Boolean, default: false },
        border: { type: String, default: "2px solid red" },
        backgroundcolor: { type: String, default: "red" },
    },
    setup() {
        let chiHienThiViTriDangChon = ref(false);
        let data = reactive({ DanhSachCho: [], choDaChon: [] });
        let model = reactive({ choDaChon: false });
        return { data, model, chiHienThiViTriDangChon };
    },
    computed: {
        ...mapGetters("soDoCho", [
            "reloadMaTranCho",
            "getTangXeDaChon",
            "removeTatCaChoDaChon",
            "removeMotChoDaChon",
        ]),
        ...mapGetters("trangChu", ["getCauHinhChung"]),
        DanhSachCho() {
            return this.data.DanhSachCho;
        },
    },
    methods: {
        ...mapMutations("soDoCho", [
            "setChoDaChon",
            "setDanhSachChoDaChon",
            "resetData",
            "setKichHoatGhePhu",
        ]),
        ...mapActions("soDoCho", ["layMaTranCho", "layDanhSachTangXe"]),
        checkRole(roleName) {
            if (this.$Core.Auth.TokenParsed.resource_access.BanVeTaiBen) {
                let arrRole =
                    this.$Core.Auth.TokenParsed.resource_access.BanVeTaiBen
                        .roles;
                return arrRole.some((e) => e == roleName);
            } else {
                return false;
            }
        },
        isEmpty(dong, cot) {
            let item = this.data.DanhSachCho.filter(
                (f) => f.dong === dong && f.cot === cot
            );
            return item.length > 0 ? true : false;
        },
        hienThiCho(dong, cot) {
            let ttchoDaNgoi = this.$t("trangThai.DaNgoi");
            let item = this.data.DanhSachCho.filter(
                (f) => f.dong === dong && f.cot === cot
            );
            let Cho = item.length > 0 ? item[0] : null;
            let styl = `;padding: 4px;box-sizing: border-box;border-radius: 8px;text-align: center;width: 100%;height: 72px;min-width: 34px;min-height: 34px;`;
            if (item.length > 0) {
                // opacity:${!Cho.hienThi ? "0.2" : ""}
                return `
                <div class="khungghe" 
                    style="
                        z-index: 0;
                        width: calc(100% + 24px);
                        height: calc(100% - 4px);
                        background: #d6d6d6;
                        position: absolute;
                        top: 16px;
                        left: -12px;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    "
                ></div>
                <div class="lungghe"
                    style="
                        position: absolute;
                        z-index: 3;
                        background: #d6d6d6;
                        width: calc(100% - 16px);
                        height: 12px;
                        bottom: -2px;
                        left: 8px;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    "
                ></div>

                <div class='row justify-center align-center font-24' 
                    style='
                    color:${Cho.mauChu};
                    background-color:${Cho.mauNen};
                    border:1px solid #dadce0 !important;
                    ${
                        this.useborder && Cho.choDaChon
                            ? "border:" + this.border + ";"
                            : ""
                    }
                    ${
                        this.usebackground && Cho.choDaChon
                            ? "background-color:" + this.backgroundcolor + ";"
                            : ""
                    }
                    ${styl}
                    '>
                    <span style="transform: rotate(-90deg)">${
                        Cho.TrangThai == ttchoDaNgoi
                            ? "<i class='mdi mdi-account-check white--text'></i>"
                            : Cho.tenCho
                    }</span>
                    
                </div>`;
            }
            return `<div class='row justify-center align-center' style='${styl}; opacity: 0'>x</div>`;
        },
        chonCho(dong, cot) {
            //Không được chọn
            if (this.readonly) {
                return;
            }
            if (!this.duocPhepChon(dong, cot)) {
                return;
            }
            //Lấy chỗ
            let item = this.data.DanhSachCho.filter(
                (f) => f.dong === dong && f.cot === cot
            )[0];
            if (item) {
                this.capNhatCho(item);
                //Lưu lại chỗ đã chọn để khi chọn tầng không bị mất
                this.luuCacChoDaChon(item);
            }
        },
        capNhatCho(item, chon) {
            if (!item) {
                return;
            }
            //Tải lại
            this.data.DanhSachCho = this.data.DanhSachCho.map((e) => {
                if (item.id == e.id) {
                    e.choDaChon = chon ? chon : !item.choDaChon;
                    e.hienThi = this.chiHienThiViTriDangChon
                        ? !e.hienThi
                        : true;
                }
                return e;
            });
        },
        response(item, DanhSachChoDaChon) {
            //Gửi đi
            this.$emit("SoDoCho", item, DanhSachChoDaChon);
            this.setChoDaChon(item);
        },
        checked(dong, cot) {
            let item = this.data.DanhSachCho.filter(
                (f) => f.dong === dong && f.cot === cot
            );
            if (item.length > 0) {
                if (item[0]) {
                    return item[0].choDaChon;
                }
            }
            return false;
        },
        duocPhepChon(dong, cot) {
            let item = this.data.DanhSachCho.filter(
                (f) => f.dong === dong && f.cot === cot
            );
            if (item.length > 0) {
                if(item[0].loaiCho == 5){
                    //Vị trí lái xe
                    return false;
                }
                if (item[0].trangThai == 1) {
                    //Vị trí trống
                    return true;
                }
                return false;
            }
            return false;
        },
        chiHienThiViTriDaChon(hienthi) {
            this.data.DanhSachCho.forEach((e) => {
                if (!e.choDaChon) {
                    e.hienThi = !hienthi;
                }
            });
        },
        luuCacChoDaChon(item) {
            let ChoDaChonTruocDo = JSON.parse(
                localStorage.getItem("choDaChon")
            );
            let DanhSachChoDaChon = ChoDaChonTruocDo ? ChoDaChonTruocDo : [];
            this.data.DanhSachCho.forEach((e, i) => {
                if (e.id === item.id) {
                    if (e.choDaChon) {
                        DanhSachChoDaChon.push(e);
                    } else {
                        DanhSachChoDaChon = DanhSachChoDaChon.filter(
                            (f) => f.id != e.id
                        );
                    }
                }
            });
            //Số chỗ tối đa được chọn
            let SoChoToiDa = this.getCauHinhChung.soChoToiDa;
            if (DanhSachChoDaChon.length > SoChoToiDa) {
                this.capNhatCho(item, false);
                this.response(item, DanhSachChoDaChon);
                return;
            }
            this.response(item, DanhSachChoDaChon);
            this.setDanhSachChoDaChon(DanhSachChoDaChon);
            localStorage.setItem(
                "choDaChon",
                JSON.stringify(DanhSachChoDaChon)
            );
        },
        giuCacChoDaChonTruocDo(data) {
            let ChoDaChonTruocDo = JSON.parse(
                localStorage.getItem("choDaChon")
            );
            if (
                !ChoDaChonTruocDo ||
                (Array.isArray(ChoDaChonTruocDo) &&
                    ChoDaChonTruocDo.length == 0)
            ) {
                return data;
            }
            data.forEach((e) => {
                ChoDaChonTruocDo.forEach((c) => {
                    if (e.id === c.id) {
                        e.choDaChon = c.choDaChon;
                    }
                });
            });
            return data;
        },
        reset() {
            localStorage.removeItem("choDaChon");
            this.data.DanhSachCho = this.data.DanhSachCho.map((e) => {
                e.choDaChon = false;
                e.hienThi = true;
                return e;
            });
            this.response(null, []);
            this.setDanhSachChoDaChon([]);
        },
    },
    mounted() {
        this.layMaTranCho(this.$route.query.chuyenDi_Id);
    },
    watch: {
        removeTatCaChoDaChon() {
            this.reset();
        },
        removeMotChoDaChon(data) {
            try {
                let item = Object.assign({}, data);
                this.chonCho(item.dong, item.cot);
            } catch (error) {
                console.log(
                    "🚀 ~ file: SoDoCho.vue ~ line 366 ~ removeMotChoDaChon ~ error",
                    error
                );
            }
        },
        resetData() {
            this.reset();
        },
        async reloadMaTranCho() {
            let maTranCho = await this.layMaTranCho(
                this.$route.query.chuyenDi_Id
            );
            let self = this;
            let data = maTranCho.map((e) => {
                return {
                    id: e.id,
                    idTang: e.idTang,
                    tenCho: e.tenCho,
                    mauNen:e.trangThai.maMau,
                    mauChu:e.trangThai.idTrangThai == 1 ? "#000000de":"white",
                        // e.trangThai.idTrangThai == 1 ? "#03A9F4" : "#BFBFBF",
                    //     ? e.trangThai.maMau
                    //     : e.loaiCho.maMau,
                    choDaChon: false,
                    hienThi: true,
                    trangThai: e.trangThai.idTrangThai,
                    loaiCho: e.loaiCho.id,
                    giaTien: e.giaTien,
                    dong: e.viTriHang,
                    cot: e.viTriCot,
                };
            });
            //Giữ các chỗ đã chọn khi chuyển tầng
            this.data.DanhSachCho = this.giuCacChoDaChonTruocDo(data);
            //
            let ChoDaChonTruocDo = JSON.parse(
                localStorage.getItem("choDaChon")
            );
            if (ChoDaChonTruocDo && Array.isArray(ChoDaChonTruocDo)) {
                this.setDanhSachChoDaChon(ChoDaChonTruocDo);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.sodoghe-style {
    height: 1224px;
    width: 600px;
    position: relative;
    margin-left: 36%;
    margin-top: -300px;
    transform: rotate(90deg);
}
.sodoghe-style .denxe {
    background-repeat: no-repeat;
}
.thanhxe {
    position: absolute;
    top: 0;
    left: 0px;
    width: 610px;
    height: 1160px;
    border: 4px solid #aaa;
    border-radius: 16px;
    z-index: 0;
    background-color: #f8f8f8;
}
.checkbox-style .dx-checkbox-icon {
    border: unset !important;
    background-color: #f8f8f8;
}
.checkbox-style .dx-checkbox-icon:before {
    color: #03a9f4;
}
.scroll-sodoghe-style::-webkit-scrollbar {
    display: none;
}
.rotate {
    transform: rotate(-90deg);
}
::v-deep.checkbox-style .dx-checkbox-icon {
    border: unset !important;
    background-color: white !important;
}
::v-deep.checkbox-style .dx-checkbox-icon:before {
    color: #03a9f4;
}
.scroll-cho::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #ddd;
    border-radius: 50px;
}

.scroll-cho::-webkit-scrollbar-track {
    border-radius: 10px;
}
.scroll-cho::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ddd;
}

.wheel-font-left {
    position: absolute;
    width: var(--distance);
    height: 128px;
    top: 20%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-font-right {
    position: absolute;
    width: var(--distance);
    height: 128px;
    top: 20%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.wheel-back-left {
    position: absolute;
    width: var(--distance);
    height: 128px;
    top: 80%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-back-right {
    position: absolute;
    width: var(--distance);
    height: 128px;
    top: 80%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.guong-trai {
    position: absolute;
    left: -24px;
    top: -12px;
    height: 48px;
    border-radius: 48px;
    border: 16px solid #ccc;
    border-top: 32px solid #ccc;
    padding: 8px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-35deg);
}
.guong-phai {
    position: absolute;
    right: -24px;
    top: -12px;
    height: 48px;
    border-radius: 48px;
    border: 16px solid #ccc;
    border-top: 32px solid #ccc;
    padding: 8px;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: rotate(35deg);
}
.buonglai {
    position: absolute;
    width: calc(100% - 48px);
    height: 48px;
    background: #dadce0;
    border-radius: 16px;
    top: 64px;
    left: 24px;
    z-index: 2;
}
.donghoto {
    width: 36px;
    height: 24px;
    background: #fff;
    position: absolute;
    top: -4px;
    left: 32px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.bangdientu {
    width: 48px;
    height: 24px;
    background: #fff;
    position: absolute;
    top: -4px;
    left: 112px;
    border-radius: 2px;
}
.donghotrai {
    width: 24px;
    height: 24px;
    background: #fff;
    position: absolute;
    top: -4px;
    left: 0;
    border-radius: 50px;
}
.donghophai {
    width: 24px;
    height: 24px;
    background: #fff;
    position: absolute;
    top: -4px;
    left: 76px;
    border-radius: 50px;
}
.ghelai {
    position: absolute;
    background: #fff;
    width: 40px;
    height: 24px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    bottom: -8px;
    z-index: 2;
}
.tayghelaixe {
    z-index: 0;
    width: calc(100% + 8px);
    height: 20px;
    background: #ddd;
    position: absolute;
    bottom: -12px;
    left: -4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.lungghelaixe {
    position: absolute;
    z-index: 2;
    background: #ddd;
    width: 24px;
    height: 4px;
    bottom: -8px;
    left: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.hang-xe {
    width: 100%;
    min-height: 80px;
    /* display: block; */
}
.hang-xe .vitri {
    margin: 0 8px;
    float: left;
    max-width: 64px;
}
</style>